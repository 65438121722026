<template>
  <div class="h2 mb-4 text-secondary">Create Portal User</div>
  <form>
    <div class="form-group">
      <label for="newPortalUserName" class="font-weight-bold">User Name</label>
      <input
        id="newPortalUserName"
        v-model="newPortalUserName"
        type="text"
        class="form-control"
        placeholder="User Name"
      >
    </div>
    <div class="form-group">
      <label for="newPortalUserPassword" class="font-weight-bold">Password</label>
      <input
        id="newPortalUserPassword"
        v-model="newPortalUserPassword"
        type="password"
        class="form-control"
        placeholder="Password"
      >
    </div>
    <div class="font-weight-bold">Select Companies which is new portal user allowed to see</div>
    <div class="form-check">
      <div v-for="company in allCompanies" :key="company">
        <input
          v-model="selectedCompanies"
          class="form-check-input"
          type="checkbox"
          name="selectedCompanies"
          :value="company.id"
        >
        <label for="selectedCompanies" class="pr-1"> {{ company.companyName }} </label>
      </div>
    </div>
    <div class="font-weight-bold">Users role</div>
    <div class="form-check">
      <input
        id="role0"
        v-model="roleId"
        class="form-check-input"
        type="radio"
        name="roleId"
        value="0"
      >
      <label for="role0" class="form-check-label"> Member </label>
    </div>
    <div class="form-check">
      <input
        id="role1"
        v-model="roleId"
        class="form-check-input"
        type="radio"
        name="roleId"
        value="1"
      >
      <label for="role1" class="form-check-label"> Admin </label>
    </div>
    <button class="btn btn-success mt-3" @click.prevent="createNewPortalUser">Create New Portal User</button>
  </form>
  <div v-if="errors" class="text-danger pt-3"> {{ errors }}</div>
  <div v-if="success" class="text-success pt-3"> {{ success }}</div>
</template>

<script>
export default {
  data () {
    return {
      selectedCompanies: [],
      newPortalUserPassword: '',
      newPortalUserName: '',
      roleId: '',
      errors: '',
      success: '',
    }
  },
  computed: {
    allCompanies () {
      return this.$store.state.allCompanies
    },
  },
  mounted () {
    this.$store.dispatch('getAllCompanies')
  },
  methods: {
    async createNewPortalUser () {
      if(this.newPortalUserPassword && this.newPortalUserName) {
        const newPortalUser = { 
          userName: this.newPortalUserName,
          userPassword: this.newPortalUserPassword,
          selectedCompanies: this.selectedCompanies,
          roleId: this.roleId
        }
        await this.$store.dispatch('addNewPortalUser', {
          newPortalUser
        })
          .then(response => {
            if(response){
              this.success = response.data
            }
          })
          .catch (error => {
            this.errors = error.response.data
          }) 
      } else {
        this.errors = 'Name and password field cannot be empty'
      }

    },
  }
}
</script>